import  React,{useEffect, useState, useRef} from "react";
import LeagueListPage from "./components/List.component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import LeagueFormDialogue from "./components/Form";
import { useDispatch } from "react-redux";
import { getAllLeagueTemplate,createLeagueTemplate } from "../../../api/leagueTemplate/leagueTemplateAction";
import LeagueDrawerComponent from "./components/Drawer";
import { toast } from 'react-toastify';

export default function LeagueComponentPage(props) {
    
    //const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const handleClose = () => setShow(false);
    const isFetch = useRef(false);
    const handleShow = () => {
      setShow(true)
    }
    const handleEdit = (row) => {
      setRow(row);
      setShow(true)
    }
    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
        if (confirmDelete) {
            console.log(`Deleted ID: ${id}`);
        }
    }

    // drawer
    const [isOpen, setIsOpen] = useState(false);
    const [singleRowData,setSingleRowData] = useState({})

    const toggleDrawer = (id) => {
        setSingleRowData(id)
        setIsOpen(!isOpen);
    };

    const columns = [
      {
        name: 'S.No',
        selector: row => row?.s_n,
        sortable: true,
      },
      {
        name: 'totalParticipants',
        selector: row => row?.totalParticipants,
        sortable: true,
      },
      {
        name: 'levelPercentages',
        selector: row => row?.levelPercentages,
        sortable: true,
      },
      {
        name: 'topLevelParticipants',
        selector: row => row?.topLevelParticipants,
        sortable: true,
      },
      {
        name: 'totalPrizePool',
        selector: row => row?.totalPrizePool,
        sortable: true,
      },
      {
        name: 'status',
        selector: row => row?.status,
        sortable: true,
      },
      {
        name: 'allowauto',
        selector: row => row?.allowauto,
        sortable: true,
      },
      {
        name: 'marginPercentage',
        selector: row => row?.marginPercentage,
        sortable: true,
      },
      {
        name: 'user_id',
        selector: row => row?.user_id,
        sortable: true,
      },
      {
        name: 'entryfees',
        selector: row => row?.entryfees,
        sortable: true,
      },
      {
        name: 'Action',
        width: '100px',
        cell: row => (
          <div className="d-flex gap-2">
            <button 
              onClick={() => handleEdit(row)} 
              className="btn btn-secondary"
              title="Edit"
            >
              <FontAwesomeIcon icon={faPencilAlt } />
            </button>
            <button 
              onClick={() => toggleDrawer(row)} 
              className="btn btn-info text-white"
              title="Edit"
            >
              <FontAwesomeIcon icon={faEye } />
            </button>
          </div>
        ),
      },
    ];

    const validate = (row) => {
      if(row) {
        dispatch(createLeagueTemplate(row))
        toast.success('Data save Successfuly!');
        // do update
      } else {
        // do create
      }
      setShow(false);
    }

    useEffect(()=> {
      if(data) {
        dispatch(getAllLeagueTemplate());
        setData(data)
      }
    },[data])

    useEffect(()=> {
      if(!isFetch.current && data) {
        dispatch(getAllLeagueTemplate());
        setData(data)
        isFetch.current = true;
      }
    },[!isFetch.current])
    
    useEffect(() => {
      if(props.result && Array.isArray(props.result.data)) {
        const updateData  =  props.result.data.map((item, index) => ({
          ...item,
          s_n: index + 1,
        }))
        setData(updateData);
      }
    },[props.result])
    
    return(
        <>
        <LeagueListPage data={data} columns={columns}  handleShow={handleShow}/>
        <LeagueFormDialogue
            show={show}
            handleClose={handleClose}
            setRow={setRow}
            row={row}
            validate={validate}
          />
          <LeagueDrawerComponent singleRowData={singleRowData} isOpen={isOpen} toggleDrawer={toggleDrawer} />
        </>
    )
}