import BannerPage from './Banner.component';
import { connect } from 'react-redux';
import { getAllBanner, createBanner, updateBanner } from '../../api/banner/bannerAction';
const mapDispatchToProps = {
    getAllBanner,
    createBanner,
    updateBanner
}
const mapStateToProps = state => ({
    isLoading: state.bannerPage.isLoading,
    isSaved: state.bannerPage.isSaved,
    result: state.bannerPage.result,
    metadata: state.bannerPage.result,
    count: 1,
});
export const BannerPageContainer = connect(mapStateToProps, mapDispatchToProps)(BannerPage); 