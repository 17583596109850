import React from "react";
import { RoutePath  } from "../route";
import HeaderPage from "../partials/Header";
import { Container } from "react-bootstrap"; 
export default function AppRoot() {
   return (
    <>
        <HeaderPage/>
        <Container>
            <RoutePath/>
        </Container>
    </>
    );
}