export const GET_LEAGUE_TEMPLATE_ALL = "GET_LEAGUE_TEMPLATE_ALL";
export const GET_ALL_LEAGUE_TEMPLATE_FAILURE = "GET_ALL_LEAGUE_TEMPLATE_FAILURE";
export const GET_ALL_LEAGUE_TEMPLATE_SUCCESS = "GET_ALL_LEAGUE_TEMPLATE_SUCCESS";


export const CREATE_LEAGUE_TEMPLATE = "CREATE_LEAGUE_TEMPLATE";
export const CREATE_LEAGUE_TEMPLATE_FAILURE = "CREATE_LEAGUE_TEMPLATE_FAILURE";
export const CREATE_LEAGUE_TEMPLATE_SUCCESS = "CREATE_LEAGUE_TEMPLATE_SUCCESS";

 

 