import { combineReducers } from 'redux';
import { tournamentReducer as tournamentPage } from '../api/tournament/tournamentReducer';
import { matchReducer as matchPage } from '../api/match/matchReducer';
import { leagueTemplateReducer as leagueTemplatePage} from '../api/leagueTemplate/leagueTemplateReducer';
import { userReducer as userPage } from '../api/user/userReducer';
import { bannerReducer as bannerPage } from '../api/banner/bannerReducer';

const appReducer = combineReducers({
    tournamentPage,
    matchPage,
    leagueTemplatePage,
    userPage,
    bannerPage
});
export default appReducer;