import React from "react";
import {Card,CardBody,Col,Container,Form,Row,Button,Accordion,Table,} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCopy,faDatabase,faCreditCard,faEyeLowVision,faBuildingColumns,faFileInvoice,faUser,faCoins,faCalendar} from "@fortawesome/free-solid-svg-icons";
import "../../../index.css";
import CountUp from "react-countup";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";


const DashboardCard = () => {
  return (
    <div>
      <Row>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-all-users' className="dash-count d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={100} />
              </h4>
              <h5>Total Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="2xl"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-active-users' className="dash-count das1 d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={77} />
              </h4>

              <h5>Total Active Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-unverified-users' className="dash-count das2 d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={20} />
              </h4>

              <h5>Total Unverified Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                style={{ color: "rgba(0,0,0,0.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-banned-users' className="dash-count das3 d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={3} />
              </h4>

              <h5>Total Banned Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col xs={6} md={3}>
          <Link to='/admin/all-bets-log' className="dash-count d-flex justify-content-between" style={{backgroundColor:'#ff6b6b'}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={100} />
              </h4>
              <h5>Total Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="2xl"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/pending-bets-log' className="dash-count das1 d-flex justify-content-between" style={{backgroundColor:'#ffc107'}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={77} />
              </h4>

              <h5>Total Pending Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/won-bets-log' className="dash-count das2 d-flex justify-content-between" style={{backgroundColor:'#e91e63 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={20} />
              </h4>

              <h5>Total Won Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="lg"
                style={{ color: "rgba(0,0,0,0.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/lost-bets-log' className="dash-count das3 d-flex justify-content-between" style={{backgroundColor:'#ff9800'}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={3} />
              </h4>

              <h5>Total Lost Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col xs={6} md={3}>
          <Link to='/admin/refunded-bets-log' className="dash-count d-flex justify-content-between" style={{backgroundColor:'#673ab7 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={100} />
              </h4>
              <h5>Refunded Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="2xl"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/all-event-match' className="dash-count das1 d-flex justify-content-between" style={{backgroundColor:'#4caf50 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={77} />
              </h4>

              <h5>All Events</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/running-event-match' className="dash-count das2 d-flex justify-content-between" style={{backgroundColor:'#2196f3 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={20} />
              </h4>

              <h5>Running Events</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                style={{ color: "rgba(0,0,0,0.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/upcoming-event-match' className="dash-count das3 d-flex justify-content-between" style={{backgroundColor:'#009688 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={3} />
              </h4>

              <h5>Upcoming Events</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardCard;
