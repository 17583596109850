import React, { useState } from "react";
import { Button, Modal, Form, FormControl, Row } from "react-bootstrap";
export default function BannerFormDialogue (props) {
  const { row, setRow, show, handleClose, handleImageChange, imagePreview, validate } = props;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => {
          e.preventDefault();
          validate(row);
        }}>
          <Modal.Body>

            <Form.Group className="mb-3">
              <Form.Label><small>Image (JPG format is standard)</small></Form.Label>
              <FormControl
                type="file"
                name="image"
                id="image"
                onChange={(e) => handleImageChange(e)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label><small>Banner Name</small></Form.Label>
              <FormControl
                type="text"
                name="name"
                id="name"
                onChange={(e) => setRow({
                  ...row,
                  name: e.target.value
                })} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label><small>Url Details</small></Form.Label>
              <FormControl
                type="text"
                name="url_details"
                id="url_details"
                onChange={(e) => setRow({
                  ...row,
                  url_details: e.target.value
                })} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label><small>Banner Description</small></Form.Label>
              <FormControl
                type="text"
                name="description"
                id="description"
                onChange={(e) => setRow({
                  ...row,
                  description: e.target.value
                })} />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>
                <b>Status</b>
              </Form.Label>
              <Form.Select value={row?.status || ''}
                onChange={(e) => setRow({
                  ...row,
                  status: e.target.value
                })}

              >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </Form.Select>
            </Form.Group> */}

            <Row>
              <div id="imageContain">
                <img
                  id="image-preview"
                  className="img-fluid"
                  src={imagePreview ? imagePreview : "https://bettpro.s7template.com/public/images/no-image.png"}
                  alt="Preview"
                />
              </div>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}