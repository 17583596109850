import { connect } from 'react-redux';
import ManageUsersPage from './ManageUsers.component';
import { getAllUser } from '../../api/user/userAction';
const mapDispatchToProps = {
    getAllUser
}
const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isSaved: state.userPage.isSaved,
    result: state.userPage.result,
    metadata: state.userPage.result,
    count: 1,
});
export const ManageUsersPageContainer = connect(mapStateToProps, mapDispatchToProps)(ManageUsersPage); 