export const GET_ALL_BANNER_INIT = "GET_ALL_BANNER_INIT";
export const GET_ALL_BANNER_FAILURE = "GET_ALL_BANNER_FAILURE";
export const GET_ALL_BANNER_SUCCESS = "GET_ALL_BANNER_SUCCESS";

export const CREATE_BANNER_INIT = "CREATE_BANNER_INIT";
export const CREATE_BANNER_FAILURE = "CREATE_BANNER_FAILURE";
export const CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS";

export const UPDATE_BANNER_INIT = "UPDATE_BANNER_INIT";
export const UPDATE_BANNER_FAILURE = "UPDATE_BANNER_FAILURE";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";

 

 