import React, { useEffect, useState, useRef } from "react";
import BannerList from './components/List';
import BannerFormDialogue from "./components/Form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { getAllBanner, createBanner } from "../../api/banner/bannerAction";
import { toast } from 'react-toastify';

export default function BannerPage (props) {

  const dispatch = useDispatch();
  const isFetch = useRef(false);
  const [data, setData] = useState([]);
  const [row, setRow] = useState({});
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setImagePreview(null);
    setShow(true)
  }
  const handleEdit = (row) => {
    console.log(`Edit clicked for ID: ${row.id}`);
    setShow(true)
  }

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
    if (confirmDelete) {
      // Proceed with the delete action
      console.log(`Deleted ID: ${id}`);
    }
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.s_n,
      sortable: true,
    },
    {
      name: 'banner',
      cell: row => {
        return (
          row.banner
            ? <img
              src={`data:image/jpg;base64,${row.banner}`}
              alt="Banner"
              width="50"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            : null
        );
      }
    },
    {
      name: 'status',
      cell: row => row.banner_status ? 'ACTIVE' : 'INACTIVE',
    },
    // {
    //   name: 'Image',
    //   cell: row => <img src={row.image} alt="item" width="50" />,
    // },
    // {
    //   name: 'Status',
    //   selector: row => row.status === 1 ? 'Active' : 'Inactive',
    //   sortable: true,
    // },
    {
      name: 'Action',
      cell: row => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-link"
            title="Edit"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-link text-danger"
            title="Delete"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setRow({ ...row, Banner_img: file });
      };
      reader.readAsDataURL(file);

    }
  }

  const validate = (row) => {
    if (row.id && row.id !== undefined) {
      console.log('Update action goes here');
    } else {
      dispatch(createBanner(row));
    }
    toast.success('Data save Successfuly!');
  }
  useEffect(() => {
    if (!isFetch.current && data) {
      dispatch(getAllBanner());
      setData(data)
      isFetch.current = true;
    }

  }, [!isFetch.current])

  useEffect(() => {
    if (props.result && Array.isArray(props.result.data)) {
      const updateData = props.result.data.map((item, index) => ({
        ...item,
        s_n: index + 1,

      }))
      setData(updateData);
    }
  }, [props.result])

  return (
    <>
      <BannerList data={data} columns={columns} handleShow={handleShow} />
      <BannerFormDialogue validate={validate} row={row} setRow={setRow} show={show} handleClose={handleClose} handleImageChange={handleImageChange} imagePreview={imagePreview} />
    </>
  )

}