import * as actionTypes from "./leagueTemplateActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
 
export const getAllLeagueTemplate = () => {
  const headers = {
    'status': "ACTIVE",            
    'allowauto': "ACTIVE", 
  };
    return (dispatch) => {
        dispatch(getAllLeagueInitTemplate());
        axios.get(`${REACT_APP_CRICKET_URL}/admin/v1/leaguetemplete`, { headers: headers })
          .then((response) => {
            dispatch(getAllLeagueTemplateSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllLeagueTemplateFailure());
          });
      };
}
export const getAllLeagueInitTemplate = () => ({
    type: actionTypes.GET_LEAGUE_TEMPLATE_ALL,
});
export const getAllLeagueTemplateFailure = () => ({
    type: actionTypes.GET_ALL_LEAGUE_TEMPLATE_FAILURE,
});
export const getAllLeagueTemplateSuccess = (data) => ({
    type: actionTypes.GET_ALL_LEAGUE_TEMPLATE_SUCCESS,
    payload: { data },
});




export const createLeagueTemplate = (params) => {
  const headers = {
    'Content-Type': 'application/json',
    'status': "ACTIVE",            
    'allowauto': "ACTIVE", 
  };
  
  const data = {
    templeteId: 'template123',
    totalParticipants: params.totalParticipants ?? '',
    levelPercentages: Array.isArray(params.levelPercentages)
      ? params.levelPercentages.map(item => String(item))
      : [],
    topLevelParticipants: params.topLevelParticipants ?? '',
    totalPrizePool: params.totalPrizePool ?? '',
    status: params.status ?? '',
    marginPercentage: params.marginPercentage ?? '',
    user_id: params.user_id ?? '',
    entryfees: params.entryfees ?? '',
  };
  return (dispatch) => {
        dispatch(createLeagueInitTemplate());
        console.warn(data);
        axios.post(`${REACT_APP_CRICKET_URL}/admin/v4/leaguetemplete`, data, { headers })
          .then((response) => {
            dispatch(createLeagueTemplateSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(createLeagueTemplateFailure());
          });
      };
}
export const createLeagueInitTemplate = () => ({
    type: actionTypes.CREATE_LEAGUE_TEMPLATE,
});
export const createLeagueTemplateFailure = () => ({
    type: actionTypes.CREATE_LEAGUE_TEMPLATE_FAILURE,
});
export const createLeagueTemplateSuccess = (data) => ({
    type: actionTypes.CREATE_LEAGUE_TEMPLATE_SUCCESS,
    payload: { data },
});

