import * as actionTypes from "./bannerActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
 
export const getAllBanner = () => {
  const headers = {};
    return (dispatch) => {
        dispatch(getAllBannerInit());
        axios.get(`${REACT_APP_CRICKET_URL}/v5/bannerget`, {})
          .then((response) => {
            dispatch(getAllBannerSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllBannerFailure());
          });
      };
}
export const getAllBannerInit = () => ({
    type: actionTypes.GET_ALL_BANNER_INIT,
});
export const getAllBannerFailure = () => ({
    type: actionTypes.GET_ALL_BANNER_FAILURE,
});
export const getAllBannerSuccess = (data) => ({
    type: actionTypes.GET_ALL_BANNER_SUCCESS,
    payload: { data },
});



export const createBanner = (data) => {
  const headers = {};
  const form = new FormData();
  form.append('name', data.name);
  form.append('url_details', data.url_details);
  if (data.Banner_img instanceof File) {
    form.append('Banner_img', data.Banner_img); 
  }
  form.append('description', data.description);
    return (dispatch) => {
        dispatch(CreateBannerInit());
        axios.post(`${REACT_APP_CRICKET_URL}/v5/banner`, form, { withCredentials: true })
          .then((response) => {
            dispatch(CreateBannerSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(CreateBannerFailure());
          });
      };
}
export const CreateBannerInit = () => ({
    type: actionTypes.CREATE_BANNER_INIT,
});
export const CreateBannerFailure = () => ({
    type: actionTypes.CREATE_BANNER_FAILURE,
});
export const CreateBannerSuccess = (data) => ({
    type: actionTypes.CREATE_BANNER_SUCCESS,
    payload: { data },
});



export const updateBanner = (data) => {
  const headers = {};
    return (dispatch) => {
        dispatch(UpdateBannerInit());
        axios.get(`${REACT_APP_CRICKET_URL}/v5/bannerget`, {})
          .then((response) => {
            dispatch(UpdateBannerSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(UpdateBannerFailure());
          });
      };
}
export const UpdateBannerInit = () => ({
    type: actionTypes.UPDATE_BANNER_INIT,
});
export const UpdateBannerFailure = () => ({
    type: actionTypes.UPDATE_BANNER_FAILURE,
});
export const UpdateBannerSuccess = (data) => ({
    type: actionTypes.UPDATE_BANNER_SUCCESS,
    payload: { data },
});

