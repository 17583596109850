import  React from "react";
import { Card, CardBody, Col, Container, Form, Row, Button,Accordion, Table  } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faDatabase,
  faCreditCard,
  faEyeLowVision,
  faBuildingColumns,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import '../../index.css';
import CountUp from "react-countup";
import Swal from "sweetalert2";
import { AreaChart, DonutChart } from "./chart/Chart";
import DashboardCard from "./component/DashboardCard";

export default function DashboardPage() {
    return(
        <>
            {/* cards */}
            <DashboardCard/>
            
            <Row>
                <Col md={7}>
                    <Card>
                        <CardBody>
                            <div className="card-head d-flex ms-4">
                                <div>
                                    <p className="text_small">Current Balance</p>
                                    <div className="3 fw-bold primary_color">$4657565</div>
                                </div>
                            </div>
                            <AreaChart />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={5} className="mt-3 mt-md-0 d-flex justify-content-center align-items-center">
                    <div className="w-100 h-100 align-center bg-white border rounded-2 ">
                        <DonutChart />
                    </div>
                </Col>
            </Row>

            <Row>
            <Accordion className="my-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Billing</Accordion.Header>
                    <Accordion.Body>
                        <Row className="d-flex">
                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block border-end">
                                    <h5 className="description-header">
                                        <b>$</b> 0.00 USD
                                    </h5>
                                    <span className="description-text">Today</span>
                                </div>
                            </Col>
                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block border-end">
                                    <h5 className="description-header">
                                        <b>$</b> 0.00 USD
                                    </h5>
                                    <span className="description-text">Yesterday</span>
                                </div>
                            </Col>
                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block border-end">
                                    <h5 className="description-header">
                                        <b>$</b> 0.00 USD
                                    </h5>
                                    <span className="description-text">Last 7 Days</span>
                                </div>
                            </Col>
                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block">
                                    <h5 className="description-header">
                                        <b>$</b> 0.00 USD
                                    </h5>
                                    <span className="description-text">This Month</span>
                                </div>
                            </Col>

                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block">
                                    <h5 className="description-header">
                                        <b>$</b> 0.00 USD
                                    </h5>
                                    <span className="description-text">Last Month</span>
                                </div>
                            </Col>
                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block">
                                    <h5 className="description-header">
                                        <b>$</b> 38091.00 USD
                                    </h5>
                                    <span className="description-text">This Year</span>
                                </div>
                            </Col>
                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block">
                                    <h5 className="description-header">
                                        <b>$</b> 62048.06 USD
                                    </h5>
                                    <span className="description-text">Last Year</span>
                                </div>
                            </Col>
                            <Col xs={3} md={3} className="my-3">
                                <div className="bill-description-block">
                                    <h5 className="description-header">
                                        <b>$</b> 100139.06  USD
                                    </h5>
                                    <span className="description-text">All Time</span>
                                </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </Row>

            <Row>
                <Col lg={6} md={6}>
                    <Accordion className="my-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Billing</Accordion.Header>
                            <Accordion.Body>
                                <Col xs={12} md={12} lg={12} className="my-3">
                                    <Card className="bg-warning">
                                        <CardBody>
                                            <div className="card-title">Paypal</div>
                                            <div className="card-title">0.00 USD</div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>

            <Row>
                <Col lg={12} md={12}>
                    <Accordion className="my-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Last 10 Completed Transactions</Accordion.Header>
                            <Accordion.Body>
                                <Col xs={12} md={12} lg={12} className="my-3">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                                <th>Invoice Id</th>
                                                <th>Payment Method</th>
                                                <th>Sender Number</th>
                                                <th>Amount</th>
                                                <th>Transaction Id</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>skipper@gmail.com</td>
                                                <td>dd3d</td>
                                                <td>okWallet</td>
                                                <td>ed3ed</td>
                                                <td>25486</td>
                                                <td>asdsad</td>
                                                <td>04/08/2024</td>
                                            </tr>
                                            <tr>
                                                <td>skipper@gmail.com</td>
                                                <td>dd3d</td>
                                                <td>okWallet</td>
                                                <td>ed3ed</td>
                                                <td>25486</td>
                                                <td>asdsad</td>
                                                <td>04/08/2024</td>
                                            </tr>
                                            <tr>
                                                <td>skipper@gmail.com</td>
                                                <td>dd3d</td>
                                                <td>okWallet</td>
                                                <td>ed3ed</td>
                                                <td>25486</td>
                                                <td>asdsad</td>
                                                <td>04/08/2024</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
         
        </>
    )
    
}