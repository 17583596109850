import  React, { useState } from "react";
import {  Button,Modal,Form, FormControl, Row, Col } from "react-bootstrap";
export default function AllEventFormDialogue(props) {
    const { show, handleClose,handleImageChange, imagePreview } = props; 
    return (
        <>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Tournament</b>
                  </Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">Tournament1</option>
                    <option value="2">Tournament2</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Start Date & Time</b>
                  </Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ visibility: "hidden" }}> time</b>
                  </Form.Label>
                  <Form.Control type="time" placeholder="" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> End Date & Time</b>
                  </Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ visibility: "hidden" }}> time</b>
                  </Form.Label>
                  <Form.Control type="time" placeholder="" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Team 01</b>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Team 01" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Team 02</b>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Team 02" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <FormControl
                    type="file"
                    name="image"
                    id="image"
                    onChange={(e) => handleImageChange(e)}
                  ></FormControl>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <FormControl
                    type="file"
                    name="image"
                    id="image"
                    onChange={(e) => handleImageChange(e)}
                  ></FormControl>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <div id="imageContain">
                  <img
                    id="image-preview"
                    className="img-fluid"
                    src={
                      imagePreview
                        ? imagePreview
                        : "https://bettpro.s7template.com/public/images/no-image.png"
                    }
                    alt="Preview"
                  />
                </div>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <div id="imageContain">
                  <img
                    id="image-preview"
                    className="img-fluid"
                    src={
                      imagePreview
                        ? imagePreview
                        : "https://bettpro.s7template.com/public/images/no-image.png"
                    }
                    alt="Preview"
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}