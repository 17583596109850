import React, { useState } from "react";
import { Button, Modal, Form, FormControl, Row, Col } from "react-bootstrap";

export default function LeagueFormDialogue (props) {
  const { show, handleClose, handleImageChange, imagePreview, setRow, row, validate } = props;

  const [fields, setFields] = useState([""]);

  const handleAddField = () => {
    setFields([...fields, ""]);
  };

  const handleFieldChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
    setRow({
      ...row,
      levelPercentages : newFields.filter(field => !isNaN(field) && field.trim() !== '')
    })
  };

  const handleDeleteField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    setRow({
      ...row,
      levelPercentages : newFields
    })
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add League</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => {
          e.preventDefault();
            validate(row);
          }}>
          <Modal.Body>
            <Row>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Total Participants</b>
                  </Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Total Participants" 
                    value={row.totalParticipants}
                    onChange={(e) => setRow({
                      ...row,
                      totalParticipants : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Top Level Participants</b>
                  </Form.Label>
                  <Form.Control 
                    type="number" 
                    placeholder="Top Level Participants"
                    value={row.topLevelParticipants}
                    onChange={(e) => setRow({
                      ...row,
                      topLevelParticipants : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Total Prize Pool</b>
                  </Form.Label>
                  <Form.Control 
                    type="number" 
                    placeholder="Total Prize Pool"
                    value={row.totalPrizePool} 
                    onChange={(e) => setRow({
                      ...row,
                      totalPrizePool : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Status</b>
                  </Form.Label>
                  <Form.Select value={row?.status || ''} 
                    onChange={(e) => setRow({
                      ...row,
                      status : e.target.value
                    })}
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Margin Percentage</b>
                  </Form.Label>
                  <Form.Control
                    type="number" 
                    placeholder="" 
                    value={row.marginPercentage} 
                    onChange={(e) => setRow({
                      ...row,
                      marginPercentage : e.target.value
                    })}
                    />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> User Id</b>
                  </Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="User Id" 
                    value={row.user_id} 
                    onChange={(e) => setRow({
                      ...row,
                      user_id : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Entry Fees</b>
                  </Form.Label>
                  <Form.Control 
                    type="number" 
                    placeholder="Entry Fees" 
                    value={row.entryfees} 
                    onChange={(e) => setRow({
                      ...row,
                      entryfees : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Level Percentages</b>
                  </Form.Label>
                  
                  {fields.map((field, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <Form.Control
                        type="text"
                        placeholder={`Field ${index + 1}`}
                        value={field}
                        onChange={(e) =>
                          handleFieldChange(index, e.target.value)
                        }
                        className="me-2"
                      />
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteField(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  ))}
                  <Button variant="secondary" onClick={handleAddField}>
                    Add More
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">Submit</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
