import  React from "react";
import { Card, CardBody, Col, Container, Form, Row, Button } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";
export default function LoginPage() {
    const navigate = useNavigate();
  return (
    <>
        <div className="justify-content-center align-items-center min-vh-100 bg-light">
            <Container className="p-4" style={{ maxWidth: '800px' }}>
                <Card>
                    <Row>
                        <Col md={6}>
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp" alt="login form" className='rounded-start w-100'></img>
                        </Col>
                        <Col md={6}>
                            <CardBody className='flex-column'>
                                <div className='d-flex flex-row mt-2'>
                                    {/* icon */}
                                    <span className="h1 fw-bold mb-0">Logo</span>
                                </div>
                                <h5 className="fw-normal my-4 pb-3" style={{letterSpacing: '1px'}}>Sign into your account</h5>
                                <Form.Group className="pb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" />
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" />
                                </Form.Group>
                               

                                <Button onClick={() => navigate("/admin/dashboard")} className="mb-4 px-5"  variant="success" type="submit">
                                    Submit
                                </Button>
                                <br/>
                                <a className="small text-muted" href="#!">Forgot password?</a>
                                <p className="mb-5 pb-lg-2" style={{color: '#393f81'}}>Don't have an account? <a href="#!" style={{color: '#393f81'}}>Register here</a></p>
                                <div className='d-flex flex-row justify-content-start'>
                                    <a href="#!" className="small text-muted me-1">Terms of use.</a>
                                    <a href="#!" className="small text-muted">Privacy policy</a>
                                </div>

                            </CardBody>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    </>
     
  )
}